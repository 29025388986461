
import '../styles/MatchOdd.css';
import {Tabs} from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import MatchOddsTab from "../tabs/odds/match_odds_tab";


function MatchOdds() {

    return (
        <div className='Match-tips-container'>
            <div className='Tips-Ads-box'>
                <div>
                    <Tabs defaultActiveKey='1'>
                        <TabPane tab="Today" key='1'>
                            <MatchOddsTab date={getMatchOddDate(0, 'value')} offset='0' />
                        </TabPane>
                        <TabPane tab="Tomorrow" key='2'>
                            <MatchOddsTab date={getMatchOddDate(1, 'value')} offset='1' />
                        </TabPane>
                        <TabPane tab={getMatchOddDate(2, 'label')} key='3'>
                            <MatchOddsTab date={getMatchOddDate(2, 'value')} offset='2' />
                        </TabPane>
                    </Tabs>
                </div>
                <div className='Ads-box' style={{backgroundColor: 'rgba(211, 211, 211, 0.39)'}}>
                    Ads space
                </div>
            </div>
        </div>
    );
}

const getMatchOddDate = (offset, type) => {
    let current = new Date();
    let required_date = new Date();
    required_date.setDate(current.getDate() + offset);

    if(type === 'label') {
        const options = { weekday: 'short', day: '2-digit', month: 'short' };
        const formattedDate = required_date.toLocaleDateString('en-US', options);

        const parts = formattedDate.split(' ');
        return `${parts[0]} ${parts[1]} ${parts[2]}`;
    }
    else {
        const formattedDate = required_date.toLocaleDateString();
        const dateArray = formattedDate.split('/')

        return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
    }
};

export default MatchOdds;