import {useEffect, useState} from "react";
import '../styles/Match.css';
import loadingIcon from '../assets/loading_ball_icon1.png';
import bellIcon from '../assets/notification.png';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import dayjs from "dayjs";


function Matches(props) {
    const [loading, setLoading] = useState(false);
    const [live, setLive] = useState(false);
    const [noResultFound, setNoResultFound] = useState('No available \n fixture!')
    const [loadError, setLoadError] = useState(false);
    const [loadMatches, setLoadMatches] = useState(false);
    const [currentTab, setCurrentTab] = useState(3);
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState("none");
    const [league, setLeague] = useState("none");
    const [countryId, setCountryId] = useState(0);
    const [leagueId, setLeagueId] = useState(0);
    const getRequiredMatchDate = (offset) => {
        const current = new Date();
        const required = new Date(current);

        required.setDate(current.getDate() + offset);

        // Formatting the date in "yyyy-MM-dd" format
        const formattedDate = required.toISOString().split('T')[0];

        return formattedDate || '1991-01-01';
    }
    const [matchDate, setMatchDate] = useState(getRequiredMatchDate(0));
    const [matches, setMatches] = useState([]);
    const baseUrl = "https://apiv3.apifootball.com";

    const getRequiredDisplayDate = (offset) => {
        const current = new Date();
        const required = new Date(current);

        required.setDate(current.getDate() + offset);
        const options = {
            weekday: 'short',
            day: '2-digit',
            month: 'short',
        };
        const dateFormatter = new Intl.DateTimeFormat('en-US', options);

        const formattedDate = dateFormatter.format(required);

        return formattedDate;
    };
    const getRequiredDisplayDateSmall = (offset) => {
        const current_date = new Date();
        const required_date = new Date(current_date);

        required_date.setDate(current_date.getDate() + offset);
        const options = {
            day: '2-digit',
            month: 'short',
        };
        const dateFormatter = new Intl.DateTimeFormat('en-US', options);

        const formattedDate = dateFormatter.format(required_date);

        return formattedDate;
    };
    const leagues = [
        {
            "league_id": "152",
            "league_name": "Premier League",
            "league_logo": "https://apiv3.apifootball.com/badges/logo_leagues/152_premier-league.png"
        },
        {
            "league_id": "302",
            "league_name": "La Liga",
            "league_logo": "https://apiv3.apifootball.com/badges/logo_leagues/302_la-liga.png"
        },
        {
            "league_id": "175",
            "league_name": "Bundesliga",
            "league_logo": "https://apiv3.apifootball.com/badges/logo_leagues/175_bundesliga.png"
        },
        {
            "league_id": "207",
            "league_name": "Serie A - Brazil",
            "league_logo": "https://apiv3.apifootball.com/badges/logo_leagues/207_serie-a.png"
        },
        {
            "league_id": "99",
            "league_name": "Serie A - Italy",
            "league_logo": "https://apiv3.apifootball.com/badges/logo_leagues/99_serie-a.png"
        },
        {
            "league_id": "168",
            "league_name": "Ligue 1",
            "league_logo": "https://apiv3.apifootball.com/badges/logo_leagues/168_ligue-1.png"
        },
        {
            "league_id": "3",
            "league_name": "Champions League",
            "league_logo": "https://apiv3.apifootball.com/badges/logo_leagues/3_uefa-champions-league.png"
        },
        {
            "league_id": "153",
            "league_name": "Championship",
            "league_logo": "https://apiv3.apifootball.com/badges/logo_leagues/153_championship.png"
        },
        {
            "league_id": "244",
            "league_name": "Eredivisie",
            "league_logo": "https://apiv3.apifootball.com/badges/logo_leagues/244_eredivisie.png"
        },
        {
            "league_id": "146",
            "league_name": "FA Cup",
            "league_logo": "https://apiv3.apifootball.com/badges/logo_leagues/146_fa-cup.png"
        },
        {
            "league_id": "235",
            "league_name": "Liga MX",
            "league_logo": "https://apiv3.apifootball.com/badges/logo_leagues/235_liga-mx.png"
        },
        {
            "league_id": "219",
            "league_name": "K League 1",
            "league_logo": "https://apiv3.apifootball.com/badges/logo_leagues/219_k-league-1.png"
        },
        {
            "league_id": "154",
            "league_name": "League One",
            "league_logo": "https://apiv3.apifootball.com/badges/logo_leagues/154_league-one.png"
        },
        {
            "league_id": "332",
            "league_name": "MLS",
            "league_logo": "https://apiv3.apifootball.com/badges/logo_leagues/332_mls.png"
        },
        {
            "league_id": "278",
            "league_name": "Saudi League",
            "league_logo": "https://apiv3.apifootball.com/badges/logo_leagues/278_saudi-league.png"
        },
        {
            "league_id": "322",
            "league_name": "Süper Lig",
            "league_logo": "https://apiv3.apifootball.com/badges/logo_leagues/322_super-lig.png"
        },
        {
            "league_id": "4",
            "league_name": "Europa League",
            "league_logo": "https://apiv3.apifootball.com/badges/logo_leagues/4_uefa-europa-league.png"
        },
        {
            "league_id": "28",
            "league_name": "World Cup",
            "league_logo": "https://apiv3.apifootball.com/badges/logo_leagues/28_world-cup.png"
        }
    ];

    const groupedMatches = (data) => {
        let groupedData = {};

        // Group the matches by league_id
        data.forEach((item) => {
            if (typeof item === 'object' && item.hasOwnProperty('league_id')) {
                let league = item.league_id;
                groupedData[league] = groupedData[league] || [];
                groupedData[league].push(item);
            }
        });

        // Convert the object back to an array
        let sortedValue = Object.values(groupedData);

        return sortedValue;
    };

    //load football nationalities
    useEffect(() => {
        let url = `${baseUrl}?action=get_countries&APIkey=${props.apikey}`;
        console.log(`Countries url: ${url}`);
        fetch(url).then(response => response.json())
            .then((data) => {
                setCountries(data);
            }).catch((error) => {
                console.log(`Error loading countries: ${error}`);
            });
    }, []);
    // load football matches
    useEffect(() => {
        let url = '';
        setLoading(true);
        setLoadError(false);
        if (live) {
            url = `${baseUrl}?action=get_events&from=${matchDate}&to=${matchDate}&APIkey=${props.apikey}&match_live=1`;
        }
        else {
            url = `${baseUrl}?action=get_events&from=${matchDate}&to=${matchDate}&APIkey=${props.apikey}`;
        }

        if (country !== 'none') {
            url = `${url}&country_id=${countryId}`;
        }

        if (league !== 'none') {
            url = `${url}&league_id=${leagueId}`;
        }

        fetch(`${url}`).then(response => response.json())
            .then((data) => {
                //checks if there is api error response
                if (typeof data.message !== 'undefined') {
                    setLoadError(true);
                }
                else {
                    //group the sorted matches
                    let groudData = groupedMatches(data);
                    //sort the grouped matches
                    let sortedGroup = sortGroupedMatches(groudData);

                    setMatches(sortedGroup);
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoadError(true);
                setLoading(false);
                console.log(`Matches failed: ${error}`);
            });
    }, [loadMatches]);
    //initiate websocket callback
    useEffect(() => {
        //connects web sockets for live matches
        socketsLive();
    }, [])
    //silently load matches minutes
    useEffect(() => {
        const updatedFetch = (url, type) => {
            fetch(`${url}`).then(latest => latest.json())
                .then((data) => {
                    //checks if there is api error response
                    if (typeof data.message !== 'undefined') {
                        console.log(`Subsequent fetch: ${data.message}`);
                    }
                    else {
                        //group the sorted matches
                        let groudData = groupedMatches(data);
                        //sort the grouped matches
                        let sortedGroup = sortGroupedMatches(groudData);
                        if((currentTab === 3 || currentTab === 1) &&
                            type === currentTab) {
                            if(country === 'none' && league === 'none') {
                                setMatches(sortedGroup);
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.log(`Subsequent Matches failed: ${error}`);
                });
        }

        // Fetch data every 3 minutes
        const intervalId = setInterval(() => {
            if(currentTab === 1 || currentTab === 3) {
                let url = `${baseUrl}?action=get_events&from=${matchDate}&to=${matchDate}&APIkey=${props.apikey}`;
                let liveUrl = `${baseUrl}?action=get_events&from=${matchDate}&to=${matchDate}&APIkey=${props.apikey}&match_live=1`;
                updatedFetch(url, 3);
                updatedFetch(liveUrl, 1);
            }
        }, 30 * 1000);

        // Clean up function to clear the interval when component unmounts
        return () => clearInterval(intervalId);
    }, [])
    const loadLiveMatch = () => {
        setLive(true);
        setMatchDate(getRequiredMatchDate(0));
        setLoadMatches(!loadMatches);
    }

    const loadNonLiveMatch = (offset, date) => {
        if (date.length === 0) {
            setMatchDate(getRequiredMatchDate(offset));
        }
        else {
            setMatchDate(date);
        }
        setLive(false);
        setLoadMatches(!loadMatches);
    }

    const socketsLive = () => {
        let socket = new WebSocket('wss://wss.apifootball.com/livescore?Widgetkey=' + props.apikey + '&timezone=+03:00');
        socket.onopen = function(e) {
            console.log('@@@@@@@@@@@@@@@@@@@@@ Socket Connected');
            console.log('@@@@@@@@@@@@@@@@@@@@@ Waiting data...');
        }
        socket.onmessage = function(e) {
            console.log('@@@@@@@@@@@@@@@@@@@ Socket Event Received.');
            if (e.data) {
                const data = JSON.parse(e.data);
                //analyze data
                //eventRealTimeData(data);
            }
            else {
                console.log('@@@@@@@@@@@@@@@@@@@@@@ No new data!');
            }
        }
        socket.onclose = function(){
            socket = null;
            setTimeout(socketsLive, 5000);
        }
    }

    const eventRealTimeData = (data) => {
        let match_with_goal_events = [];
        if(Array.isArray(data)) {
            data.forEach((event) => {
                //gets goal score events
                let goal_events = event.goalscorer;
                if(Array.isArray(goal_events) && goal_events.length > 0) {
                    if(event.match_status.includes('+') || !isNaN(event.match_status)) {
                        let progress_time;
                        if(event.match_status.includes("+")) {
                            let arrayTime = event.match_status.split('+');
                            progress_time = parseInt(arrayTime[0]) + parseInt(arrayTime[1]);
                        }
                        else {
                            progress_time = parseInt(event.match_status);
                        }

                        goal_events.forEach((goal) => {
                            let goal_time;
                            if(goal.time.includes("+")) {
                                let arrayTime = goal.time.split('+');
                                goal_time = parseInt(arrayTime[0]) + parseInt(arrayTime[1]);
                            }
                            else {
                                goal_time = parseInt(goal.time);
                            }
                            //checks that time difference is not more than two minutes
                            if(!((goal_time + 2) < progress_time)) {
                                match_with_goal_events.push(event)
                            }

                            console.log(`#################### Match time: ${event.match_status}, Goal time: ${goal.time}`)
                        })
                    }
                    else {
                        console.log(`Match status not Number: ${event.match_status}`)
                    }
                }
                else {
                    console.log(`Match event has no goal: ${event.goalscorer}`)
                }
            });
        }
        console.log(`Match with Goal: ${match_with_goal_events.length}`)
    }

    const sortGroupedMatches = (grouped_matches) => {
        let grouped_data;
        let sorted_grouped = [];

        //get matches that belongs to continents
        let african = grouped_matches.filter((element) => element[0].league_id === "29");
        grouped_data = grouped_matches.filter((element) => element[0].league_id !== "29");
        sorted_grouped = getNewArrayResult(sorted_grouped, african[0]);


        let asian = grouped_data.filter((element) => element[0].league_id === "11");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "11");
        sorted_grouped = getNewArrayResult(sorted_grouped, asian[0]);

        let conmebolQualifiers = grouped_data.filter((element) => element[0].league_id === "27");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "27");
        sorted_grouped = getNewArrayResult(sorted_grouped, conmebolQualifiers[0]);

        let uefaQualifiers = grouped_data.filter((element) => element[0].league_id === "24");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "24");
        sorted_grouped = getNewArrayResult(sorted_grouped, uefaQualifiers[0]);

        //get matches that belongs to World Cup
        let worldCup = grouped_data.filter((element) => element[0].league_id === "28");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "28")
        sorted_grouped = getNewArrayResult(sorted_grouped, worldCup[0]);

        //get matches that belongs to AFC World Cup Qualifiers
        let afcQualifiers = grouped_data.filter((element) => element[0].league_id === "22");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "22");
        sorted_grouped = getNewArrayResult(sorted_grouped, afcQualifiers[0]);

        //get matches that belongs to CAF World Cup Qualifiers
        let cafQualifiers = grouped_data.filter((element) => element[0].league_id === "21");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "21");
        sorted_grouped = getNewArrayResult(sorted_grouped, cafQualifiers[0]);

        //get matches that belongs to CONCAF World Cup Qualifiers
        let conCafQualifiers = grouped_data.filter((element) => element[0].league_id === "23");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "23");
        sorted_grouped = getNewArrayResult(sorted_grouped, conCafQualifiers[0]);

        //get matches that belongs to CONCAF World Cup Qualifiers
        let ofcQualifiers = grouped_data.filter((element) => element[0].league_id === "26");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "26");
        sorted_grouped = getNewArrayResult(sorted_grouped, ofcQualifiers[0]);

        //get matches that belongs to english premier league
        let englishPL = grouped_data.filter((element) => element[0].league_id === "152");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "152");
        sorted_grouped = getNewArrayResult(sorted_grouped, englishPL[0]);

        //get matches that belongs to Euro Cup Champions
        let eurocupChampions = grouped_data.filter((element) => element[0].league_id === "3");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "3");
        sorted_grouped = getNewArrayResult(sorted_grouped, eurocupChampions[0]);

        //get matches that belongs to Euro Cup Champions
        let eurocup = grouped_data.filter((element) => element[0].league_id === "4");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "4");
        sorted_grouped = getNewArrayResult(sorted_grouped, eurocup[0]);

        //get matches that belongs to Spanish La Liga
        let spanishLaLiGa = grouped_data.filter((element) => element[0].league_id === "302");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "302");
        sorted_grouped = getNewArrayResult(sorted_grouped, spanishLaLiGa[0]);

        //get matches that belongs to Bundesliga La Liga
        let germanBundesliga = grouped_data.filter((element) => element[0].league_id === "175");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "175");
        sorted_grouped = getNewArrayResult(sorted_grouped, germanBundesliga[0]);

        //get matches that belongs to Brazilian SERIE A
        let brazilianSerieA = grouped_data.filter((element) => element[0].league_id === "99");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "99");
        sorted_grouped = getNewArrayResult(sorted_grouped, brazilianSerieA[0]);

        //get matches that belongs to Italian SERIE A
        let italianSerieA = grouped_data.filter((element) => element[0].league_id === "207");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "207");
        sorted_grouped = getNewArrayResult(sorted_grouped, italianSerieA[0]);

        //get matches that belongs to France League 1
        let franceLeague1 = grouped_data.filter((element) => element[0].league_id === "168");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "168");
        sorted_grouped = getNewArrayResult(sorted_grouped,franceLeague1[0]);

        //get matches that belongs to Netherlands Eredivisie
        let eredivisieNetherlands = grouped_data.filter((element) => element[0].league_id === "244");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "244");
        sorted_grouped = getNewArrayResult(sorted_grouped,eredivisieNetherlands[0]);


        //get matches that belongs to english FA Cup
        let englishFACup = grouped_data.filter((element) => element[0].league_id === "146");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "146");
        sorted_grouped = getNewArrayResult(sorted_grouped,englishFACup[0]);

        //get matches that belongs to english championship
        let englishChampion = grouped_data.filter((element) => element[0].league_id === "153");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "153");
        sorted_grouped = getNewArrayResult(sorted_grouped,englishChampion[0]);

        //get matches that belongs to world friendlies
        let friendlies = grouped_data.filter((element) => element[0].league_id === "356");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "356");
        sorted_grouped = getNewArrayResult(sorted_grouped,friendlies[0]);

        let fifaU17 = grouped_data.filter((element) => element[0].league_id === "415");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "415");
        sorted_grouped = getNewArrayResult(sorted_grouped, fifaU17[0]);

        //get matches that belongs to FIFA U20 World Cup
        let fifaU20 = grouped_data.filter((element) => element[0].league_id === "425");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "425");
        sorted_grouped = getNewArrayResult(sorted_grouped, fifaU20[0]);

        //get matches that belongs to Women Asian World Cup Qualifiers
        let wAsianQualifiers = grouped_data.filter((element) => element[0].league_id === "717");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "717");
        sorted_grouped = getNewArrayResult(sorted_grouped, wAsianQualifiers[0]);

        //get matches that belongs to Women Europ World Cup Qualifiers
        let wEuropQualifiers = grouped_data.filter((element) => element[0].league_id === "704");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "704");
        sorted_grouped = getNewArrayResult(sorted_grouped, wEuropQualifiers[0]);

        //get matches that belongs to Women Europ World Cup Qualifiers
        let wOtherQualifiers = grouped_data.filter((element) => element[0].league_id === "25");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "25");
        sorted_grouped = getNewArrayResult(sorted_grouped,wOtherQualifiers[0]);

        //get matches that belongs to Portugal Primeira Liga
        let primeiraLigaPortugal = grouped_data.filter((element) => element[0].league_id === "266");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "266");
        sorted_grouped = getNewArrayResult(sorted_grouped,primeiraLigaPortugal[0]);

        //get matches that belongs to Mexico Liga MX
        let ligaMX = grouped_data.filter((element) => element[0].league_id === "235");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "235");
        sorted_grouped = getNewArrayResult(sorted_grouped,ligaMX[0]);

        //get matches that belongs to Turkey Super Lig
        let turkeySuperLig = grouped_data.filter((element) => element[0].league_id === "322");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "322");
        sorted_grouped = getNewArrayResult(sorted_grouped,turkeySuperLig[0]);

        //get matches that belongs to Brazilian SERIE B
        let brazilianSerieB = grouped_data.filter((element) => element[0].league_id === "75");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "75");
        sorted_grouped = getNewArrayResult(sorted_grouped,brazilianSerieB[0]);

        //get matches that belongs to Italian SERIE B
        let italianSerieB = grouped_data.filter((element) => element[0].league_id === "206");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "206");
        sorted_grouped = getNewArrayResult(sorted_grouped,italianSerieB[0]);

        //get matches that belongs to english League 1
        let englishLeague1 = grouped_data.filter((element) => element[0].league_id === "154");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "154");
        sorted_grouped = getNewArrayResult(sorted_grouped,englishLeague1[0]);

        //get matches that belongs to english League 2
        let englishLeague2 = grouped_data.filter((element) => element[0].league_id === "145");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "145");
        sorted_grouped = getNewArrayResult(sorted_grouped,englishLeague2[0]);

        //get matches that belongs to France League 2
        let franceLeague2 = grouped_data.filter((element) => element[0].league_id === "164");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "164");
        sorted_grouped = getNewArrayResult(sorted_grouped,franceLeague2[0]);

        //get matches that belongs to Spain Segunda División
        let spanishSegunda = grouped_data.filter((element) => element[0].league_id === "301");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "301");
        sorted_grouped = getNewArrayResult(sorted_grouped,spanishSegunda[0]);

        //get matches that belongs to German 2. Bundesliga
        let germanBundesliga2 = grouped_data.filter((element) => element[0].league_id === "171");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "171");
        sorted_grouped = getNewArrayResult(sorted_grouped,germanBundesliga2[0]);

        //get matches that belongs to USA MLS
        let mls = grouped_data.filter((element) => element[0].league_id === "332");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "332");
        sorted_grouped = getNewArrayResult(sorted_grouped,mls[0]);

        //get matches that belongs to Denmark SuperLiga
        let superLigaDenmark = grouped_data.filter((element) => element[0].league_id === "135");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "135");
        sorted_grouped = getNewArrayResult(sorted_grouped,superLigaDenmark[0]);

        //get matches that belongs to United Arab Emirates
        let proLeague = grouped_data.filter((element) => element[0].league_id === "328");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "328");
        sorted_grouped = getNewArrayResult(sorted_grouped,proLeague[0]);

        //get matches that belongs to english EFL Trophy
        let englishEFTrophy = grouped_data.filter((element) => element[0].league_id === "148");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "148");
        sorted_grouped = getNewArrayResult(sorted_grouped,englishEFTrophy[0]);

        //get matches that belongs to english FA Trophy
        let englishFATrophy = grouped_data.filter((element) => element[0].league_id === "144");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "144");
        sorted_grouped = getNewArrayResult(sorted_grouped,englishFATrophy[0]);

        //get matches that belongs to english National League
        let englishNLeague = grouped_data.filter((element) => element[0].league_id === "151");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "151");
        sorted_grouped = getNewArrayResult(sorted_grouped,englishNLeague[0]);

        //get matches that belongs to english National League N
        let englishNLeagueN = grouped_data.filter((element) => element[0].league_id === "150");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "150");
        sorted_grouped = getNewArrayResult(sorted_grouped,englishNLeagueN[0]);

        //get matches that belongs to english Premier League Cup
        let englishEPLCup = grouped_data.filter((element) => element[0].league_id === "716");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "716");
        sorted_grouped = getNewArrayResult(sorted_grouped,englishEPLCup[0]);

        //get matches that belongs to Russian Premier League
        let russianLeague = grouped_data.filter((element) => element[0].league_id === "344");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "344");
        sorted_grouped = getNewArrayResult(sorted_grouped,russianLeague[0]);

        //get matches that belongs to Korea K-League
        let kLeague = grouped_data.filter((element) => element[0].league_id === "219");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "219");
        sorted_grouped = getNewArrayResult(sorted_grouped,kLeague[0]);

        //get matches that belongs to Norway Eliteserien
        let eliteserien = grouped_data.filter((element) => element[0].league_id === "253");
        grouped_data = grouped_data.filter((element) => element[0].league_id !== "253");
        sorted_grouped = getNewArrayResult(sorted_grouped,eliteserien[0])

        //adding the remaining leagues
        return sorted_grouped.concat(grouped_data);
    }

    const getNewArrayResult = (old_array, child_array) => {
        if(Array.isArray(old_array)) {
            if(Array.isArray(child_array) && child_array.length > 0) {
                old_array.push(child_array);
                return old_array;
            }
            else {
                return old_array;
            }
        }
        else {
            if(Array.isArray(child_array) && child_array.length > 0) {
                let array = [];
                array.push(child_array);
                return array;
            }
            else {
                return [];
            }
        }
    }

    const handleMatchTab = (offset, index) => {
        setCurrentTab(index);
        setLeague("none");
        setCountry("none");
        if (offset === 0 && index === 1) {
            loadLiveMatch();
        }
        else {
            loadNonLiveMatch(offset, "");
        }
    }

    const handleDatePicker = (value) => {
        //sets the current tab location to date
        setCurrentTab(6);
        var date = new Date(value);
        let formattedDate = date.toISOString().split('T')[0];
        setLive(false);
        setLeague("none");
        setCountry("none");
        setMatchDate(formattedDate);
        setLoadMatches(!loadMatches);
    }

    const handleMatchByLeague = (league, id) => {
        setCountry("none");
        setLeague(league);
        setLeagueId(id);
        setLoadMatches(!loadMatches);
    }

    const handleMatchByCountry = (country, id) => {
        setLeague("none");
        setCountry(country);
        setCountryId(id);
        setLoadMatches(!loadMatches);
    }

    const handleMatchDetail = (id, leagueId, hId, aId, mDate) => {
        let key = `1q4a${props.apikey}j1Odx`;
        let location = `/match/${mDate}/${id}`;
        /*
        navigate(location, {state: {
                    'league': leagueId,
                    'home_id': hId,
                    'away_id': aId,
                    'match_date': mDate,
                    'api_key': key
            }});
         */
        //store match info locally
        localStorage.setItem("matchData", JSON.stringify({
            'league': leagueId,
            'home_id': hId,
            'away_id': aId,
            'match_date': mDate,
            'api_key': key
        }));
        //opens new tab
        window.open(location, '_blank');
    };

    const matchTime = (date) => {
        const require_date = new Date(date);
        const formatter = new Intl.DateTimeFormat('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        }).format(require_date);

        return formatter;
    }

    const getMatchStatus = (match_time, matchStatus) => {
        if (matchStatus === "Finished" ||
            matchStatus === "After ET" || matchStatus === "After Pen.") {
            return 'FT';
        }
        else if (matchStatus === "Half Time") {
            return 'HT';
        }
        else if (matchStatus === "Postponed") {
            return 'PST';
        }
        else if (matchStatus === "Cancelled") {
            return 'CANC';
        }
        else if (matchStatus === "Susp.") {
            return 'SUSP';
        }
        else if (matchStatus === "Awarded") {
            return 'AWD';
        }
        else if (matchStatus === "Abandoned") {
            return 'ABD'
        }
        else if (matchStatus === "Delayed") {
            return 'DLD'
        }
        else if (typeof matchStatus !== 'undefined' && matchStatus != null
            && matchStatus !== "") {
            return `${matchStatus}'`
        }
        else {
            return matchTime(match_time);
        }
    }

    const matchdata = (matches) => {
        return (
            <div>{
                matches.length > 0 && matches.map((grouped_matches) => (
                    Array.isArray(grouped_matches) && grouped_matches.length > 0
                    && (
                        <table border="0.5">
                            <thead>
                            <tr>
                                <th className="match-info" colSpan="5">
                                    {Array.isArray(grouped_matches) && (
                                        <span>{
                                            getMatchLeagueDisplay(
                                                grouped_matches[0]?.league_name,
                                                grouped_matches[0]?.country_name
                                            )
                                        }</span>
                                    )}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                Array.isArray(grouped_matches) && grouped_matches.length > 0 && (
                                    grouped_matches.map((match) => (
                                        <tr onClick={() => handleMatchDetail(
                                            match.match_id,
                                            match.league_id,
                                            match.match_hometeam_id,
                                            match.match_awayteam_id,
                                            match.match_date
                                        )}>
                                            <td>
                                                <div className="inner-info">
                                                    <div className="match-status"
                                                         style={{
                                                             color: getMatchStatusLive(match.match_status) ? 'darkorange' : 'black',
                                                             fontWeight: getMatchStatusLive(match.match_status) ? 'bold' : 'normal'
                                                         }}>
                                                        <span>{getMatchStatus(match.match_time, match.match_status)}</span>
                                                    </div>
                                                    <div className="match-detail">
                                                        <span className="teamNameLeft">{match.match_hometeam_name}</span>
                                                        <div className="image-score-time">
                                                            <img src={match.team_home_badge} alt="" width="12" height="12" />
                                                            <div className="score-time">
                                                                <div hidden={!hasMatchStarted(match.match_status)}>
                                                                                <span className="full-time-score">
                                                                                    {match.match_hometeam_score}-{match.match_awayteam_score}
                                                                                </span>
                                                                    <span>
                                                                                    <span className="half-time-score">
                                                                                        ({match.match_hometeam_halftime_score}-{match.match_awayteam_halftime_score})
                                                                                    </span>
                                                                                </span>
                                                                </div>
                                                                <div hidden={hasMatchStarted(match.match_status)}>
                                                                    <img src={bellIcon} alt="" width="10" height="10" />
                                                                </div>
                                                            </div>
                                                            <img src={match.team_away_badge} alt="" width="12" height="12" />
                                                        </div>
                                                        <span className="teamNameRight">{match.match_awayteam_name}</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                )
                            }
                            </tbody>
                        </table>
                    )
                ))
            }</div>);
    }

    const updatedMatchData = (matches) => {
        return (
            <div>{
                matches.length > 0 && matches.map((grouped_matches) => (
                    Array.isArray(grouped_matches) && grouped_matches.length > 0
                    && (
                        <table border="0.5">
                            <thead>
                            <tr>
                                <th className="match-info" colSpan="5">
                                    {Array.isArray(grouped_matches) && (
                                        <span>{
                                            getMatchLeagueDisplay(
                                                grouped_matches[0]?.league_name,
                                                grouped_matches[0]?.country_name
                                            )
                                        }</span>
                                    )}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                Array.isArray(grouped_matches) && grouped_matches.length > 0 && (
                                    grouped_matches.map((match) => (
                                        <tr onClick={() => handleMatchDetail(
                                            match.match_id,
                                            match.league_id,
                                            match.match_hometeam_id,
                                            match.match_awayteam_id,
                                            match.match_date
                                        )}>
                                            <td>
                                                <div className="inner-info">
                                                    <div className="match-status"
                                                         style={{
                                                             color: getMatchStatusLive(match.match_status) ? 'darkorange' : 'black',
                                                             fontWeight: getMatchStatusLive(match.match_status) ? 'bold' : 'normal'
                                                         }}>
                                                        <span>{
                                                            getMatchStatus(`${match.match_date} ${match.match_time}`, match.match_status).split(" ")[0]
                                                        }</span>
                                                        <span style={{fontSize: '8px'}}>&nbsp;{getMatchStatus(`${match.match_date} ${match.match_time}`, match.match_status).split(" ")[1]}</span>
                                                    </div>
                                                    <div className="match-detail">
                                                        <span className="teamNameLeft">{match.match_hometeam_name}</span>
                                                        <div className="image-score-time">
                                                            <img src={match.team_home_badge} alt="" width="12" height="12" />
                                                            <div className="score-time">
                                                                <div hidden={!hasMatchStarted(match.match_status)}>
                                                                    {
                                                                        hasMatchDisrupted(match.match_status) ?
                                                                            <span style={{fontSize: '9px', fontStyle: 'italic'}}>{match.match_status}</span> :
                                                                            <div>
                                                                                <span className="full-time-score">
                                                                                    {match.match_hometeam_score}-{match.match_awayteam_score}
                                                                                </span>
                                                                                <span>
                                                                                    <span className="half-time-score">
                                                                                        ({match.match_hometeam_halftime_score}-{match.match_awayteam_halftime_score})
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                    }
                                                                </div>
                                                                <div hidden={hasMatchStarted(match.match_status)}>
                                                                    <div className='match-time-bell'><img src={bellIcon} alt="" width="10" height="10" /></div>
                                                                    <div className='match-time-normal'><span>{matchTime(`${match.match_date} ${match.match_time}`)}</span></div>
                                                                </div>
                                                            </div>
                                                            <img src={match.team_away_badge} alt="" width="12" height="12" />
                                                        </div>
                                                        <span className="teamNameRight">{match.match_awayteam_name}</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                )
                            }
                            </tbody>
                        </table>
                    )
                ))
            }</div>);
    }

    return (
        <div className="Match-container">
            <div className="Left-box">
                <div>
                    <div className="coutry-head">
                        <span>Top Competitions</span>
                    </div>
                    <ul className="list-with-dividers top-competition">
                        {leagues.map((league1) => (
                            <li onClick={() => handleMatchByLeague(league1.league_name, league1.league_id)}
                                style={{ backgroundColor: league === league1.league_name ? '#ce3d04' : 'white', color: 'black' }}>
                                <div>
                                    <img src={league1.league_logo} alt="" width="14" height="14" />
                                    &nbsp; <span>{league1.league_name}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

                <div hidden={countries.length === 0}>
                    <div className="coutry-head">
                        <span>Nationalities</span>
                    </div>
                    <ul className="list-with-dividers country-list">
                        {Array.isArray(countries) && countries.length > 0
                            && countries.map((country1) => (
                                <li onClick={() => handleMatchByCountry(country1.country_name, country1.country_id)}
                                    style={{ backgroundColor: country === country1.country_name ? '#ce3d04' : 'white', color: 'black' }}>
                                    <div>
                                        <img src={country1.country_logo} alt="" width="14" height="14" />
                                        &nbsp; <span>{country1.country_name}</span>
                                    </div>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
            <div className="Center-box">
                <div className="Ob">
                    <div className="Oc">
                        <div className="Dc" onClick={() => handleMatchTab(0, 1)}
                            style={{ backgroundColor: currentTab === 1 ? '#f14d0c' : '#0f2d37' }}>
                            <span>&nbsp;LIVE&nbsp;</span>
                        </div>
                        <div className="Dc" onClick={() => handleMatchTab(-1, 2)}
                            style={{ backgroundColor: currentTab === 2 ? '#f14d0c' : '#0f2d37'}}>
                            <span className='Date-large'>
                                {getRequiredDisplayDate(-1)}
                            </span>
                            <span className='Date-small'>
                                {getRequiredDisplayDateSmall(-1).split(" ")[0]}&nbsp;{getRequiredDisplayDateSmall(-1).split(" ")[1]}
                            </span>
                        </div>
                        <div className="Dc" onClick={() => handleMatchTab(0, 3)}
                            style={{ backgroundColor: currentTab === 3 ? '#f14d0c' : '#0f2d37' }}>
                            <span>TODAY</span>
                        </div>
                        <div className="Dc" onClick={() => handleMatchTab(1, 4)}
                            style={{ backgroundColor: currentTab === 4 ? '#f14d0c' : '#0f2d37' }}>
                            <span className='Date-large'>
                                {getRequiredDisplayDate(1)}
                            </span>
                            <span className='Date-small' style={{}}>
                                {getRequiredDisplayDateSmall(1).split(" ")[0]}&nbsp;{getRequiredDisplayDateSmall(1).split(" ")[1]}
                            </span>
                        </div>
                    </div>
                    <div className="Dc2" style={{ backgroundColor: currentTab === 6 ? '#f14d0c' : '#0f2d37' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} className="customDatePicker">
                            <DesktopDatePicker onAccept={(value) => handleDatePicker(value)}
                                defaultValue={dayjs(new Date())}
                                className="customDatePicker"
                                sx={{
                                    '&.MuiTextField-root': {
                                        border: 'none',
                                        color: 'white',
                                        borderRadius: '8px',
                                        height: '100%',
                                        width: '100%'
                                    },
                                    '& .MuiInputBase-root': {
                                        height: '90%',
                                        color: 'white'
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        height: '90%',
                                        color: 'white'
                                    },
                                    '& .MuiInputBase-input': {
                                        height: '100%',
                                        color: 'white',
                                        textAlign: 'center'
                                    },
                                    '& .MuiSvgIcon-root': {
                                        height: '90%',
                                        color: 'white'
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                </div>

                <div className="loaded" hidden={loading || loadError}>
                    {
                        updatedMatchData(matches)
                    }
                </div>

                <div className="loader" hidden={!loading}>
                    <img src={loadingIcon} className='circle-progress' width='42px' height='42px' alt=''/>
                </div>

                <div style={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    hidden={!loadError && !loading}>
                    <div className="alert alert-secondary" role="alert">
                        {noResultFound}
                    </div>
                </div>
            </div>
            <div className="Right-box">
                <script async
                        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2923862268793226"
                        crossOrigin="anonymous"></script>
                <ins className="adsbygoogle"
                     style={{display: 'block'}}
                     data-ad-client="ca-pub-2923862268793226"
                     data-ad-slot="9287549615"
                     data-ad-format="auto"
                     data-full-width-responsive="true"></ins>
                <script>
                    (adsbygoogle = window.adsbygoogle || []).push({});
                </script>
            </div>
        </div>
    );

}

const getMatchStatusLive = (matchStatus) => {
    if (matchStatus === "Finished" || matchStatus === "Half Time" ||
        matchStatus === "Postponed" || matchStatus === "Cancelled" ||
        matchStatus === "Susp." || matchStatus === "Awarded" ||
        matchStatus === "" || matchStatus === "After ET" ||
        matchStatus === "After Pen." || matchStatus === "Abandoned") {
        return false;
    }
    else {
        return true;
    }
}

const hasMatchDisrupted = (matchStatus) => {
    return matchStatus === "Postponed" || matchStatus === "Cancelled" ||
        matchStatus === "Susp." || matchStatus === "Awarded" ||
        matchStatus === "Abandoned" || matchStatus === "Delayed";
}

const getMatchLeagueDisplay = (league, country) => {
    let leagueName = '';

    if (typeof league !== 'undefined' &&
        typeof country !== 'undefined') {
        if (league.includes("-")) {
            leagueName = league.split('-')[0];
        }
        else {
            leagueName = league;
        }
    }

    if (country === 'intl' || country === 'world'
        || country === "eurocup") {
        return leagueName;
    }

    return `${country} - ${leagueName}`;
}

const hasMatchStarted = (status) => {
    if (status === "Postponed" || status === "Cancelled" ||
        status === "Susp.") {
        return false;
    }
    else if (status !== "") {
        return true;
    }

    return false;
}

export default Matches;