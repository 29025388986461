import {useEffect, useRef, useState} from "react";
import '../../styles/MatchOddTab.css';
import loadingIcon from '../../assets/loading_ball_icon1.png';
import {Alert, Button, Divider, Space} from "antd";
import MatchOddItem from "../../components/match_odd_item";
import {Pagination} from "@mui/material";

function  MatchOddsTab(props) {
    const [pageSize, setPageSize] = useState(3);
    const [current, setCurrent] = useState(1);
    const [targetPage, setTargetPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [nextLoading, setNextLoading] = useState(false);
    const [noResult, setNoResult] = useState('Odds not ready!')
    const [loadError, setLoadError] = useState(false);
    const [oddCollection, setOddCollection] = useState(new Map())
    const [oddList, setOddList] = useState([]);
    const odd_container_ref = useRef(null);
    const [height, setHeight] = useState(window.innerHeight);

    const loadMatchOdds = (page, count) => {
        if(!oddCollection.has(page)) {
            setLoading(true);
            let url = `https://vs-sports-server.onrender.com/varitex/api/server/football/odds?page=${page}&offset=${props.offset}`;
            fetch(url).then(response => response.json())
                .then((data) => {
                    let oddData = data.data
                    if(typeof oddData.odds !== 'undefined' && oddData.odds.length > 0) {
                        setOddList(oddData.odds);

                        //creates cache of the odd for current page
                        const oddMap = new Map(oddCollection);
                        oddMap.set(page, oddData.odds);
                        setOddCollection(oddMap);

                        if(height >= 1600) {
                            //load the next page
                            loadNextPageOdds(page + 1, 0);
                            //delays showing the next navigation
                            setTimeout(() => {
                                let total;
                                if(oddData.total%2 !== 0) {
                                    total = Math.floor(oddData.total/2) + 1;
                                }
                                else {
                                    total = oddData.total/2;
                                }

                                setPageSize(total);
                                setCurrent(oddData.current);
                            }, 3000);
                        }
                        else {
                            setPageSize(oddData.total);
                            setCurrent(oddData.current);
                        }
                    }
                    else {
                        setLoadError(true);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    if(count === 1) {
                        loadMatchOdds(page, 2);
                    }
                    else {
                        setLoadError(true);
                        setLoading(false);
                        //prints error detail
                        console.log(`Error loading Odds: ${error}`);
                    }
                });
        }
        else {
            console.log(`The current selected page already loaded: page: ${page}`)
            setCurrent(page);
        }
    }
    const loadNextPageOdds = (page, count) => {
        if(!oddCollection.has(page)) {
            setNextLoading(true);
            let required_page = page + 1;
            let url = `https://vs-sports-server.onrender.com/varitex/api/server/football/odds?page=${required_page}&offset=${props.offset}`;
            fetch(url).then(response => response.json())
                .then((data) => {
                    let oddData = data.data
                    if(typeof oddData.odds !== 'undefined' && oddData.odds.length > 0) {
                        const oddMap = new Map(oddCollection);
                        //collect the first part
                        const oddArray = oddMap.get(page);
                        //add the subsequent list
                        oddArray.push(oddData.odds);
                        //update the first part
                        oddMap.set(page, oddArray);

                        setOddCollection(oddMap);
                    }
                    setNextLoading(false);
                })
                .catch((error) => {
                    if(count === 1) {
                        loadNextPageOdds(page, 2);
                    }
                    else {
                        setNextLoading(false);
                    }
                });
        }
        else {
            console.log(`The current selected page already loaded: page: ${page}`)
            setCurrent(page);
        }
    }
    const handlePageChange = (event, page) => {
        if(height >= 1600 && page > 1) {
            setTargetPage(page + 1);
        }
        else {
            setTargetPage(page)
        }
    }

    const handleReload = () => {
        loadMatchOdds(current, 0);
        setLoadError(false);
    }

    const fixtureFormattedDate = (date) => {
        const required = new Date(date);
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };
        const dateFormatter = new Intl.DateTimeFormat('en-US', options);

        const formattedDate = dateFormatter.format(required);

        return formattedDate;
    }

    const matchOddPager = (odd_list) => {

        return (<div>
            {
                Array.isArray(odd_list) && odd_list.length > 0 &&
                odd_list.map((match_odd, index) =>
                    <div>
                        {
                            (index === 0 || odd_list[(index - 1)].league.id !== match_odd.league.id) ?
                                <div className='League-header' style={{marginTop: (index > 0) ? '10px' : '0px'}}>
                                    <Space direction='horizontal' size='small'>
                                        <img src={match_odd.league.logo} width='16px' height='16px'/>
                                        <span>{match_odd.league.country} - {match_odd.league.name}</span>
                                    </Space>
                                    <div className='Header-bet'>
                                        <span className='Header-bet-value'>1</span>
                                        <span className='Header-bet-value'>X</span>
                                        <span className='Header-bet-value'>2</span>
                                    </div>
                                </div> : null
                        }
                        <div className='Match-odd-box1'>
                            <div className='Odd-match-time'>
                                <span>{fixtureFormattedDate(match_odd.fixture.date)}</span>
                            </div>
                            <div>
                                <Space direction='vertical' size='small'>
                                    <img src={match_odd.teams.home.logo} width='16px' height='16px'/>
                                    <img src={match_odd.teams.away.logo} width='16px' height='16px'/>
                                </Space>
                            </div>
                            <div>
                                <Space direction='vertical' size='small'>
                                    <span>{match_odd.teams.home.name}</span>
                                    <span>{match_odd.teams.away.name}</span>
                                </Space>
                            </div>
                            <div>
                                {
                                    match_odd.bookmakers.map((bookmaker, _index) => (
                                        (bookmaker.name === '1xBet') ?
                                            <div className='Bookmaker-item'>
                                                <MatchOddItem bookmarker={bookmaker} />
                                            </div> :
                                            (!has1xBet(match_odd.bookmakers) && bookmaker.name === 'Bet365') ?
                                                <div className='Bookmaker-item'>
                                                    <MatchOddItem bookmarker={bookmaker} />
                                                </div> :
                                                ((!hasBoth1xBetAndBet365(match_odd.bookmakers)) && _index === 1) ?
                                                    <div className='Bookmaker-item'>
                                                        <MatchOddItem bookmarker={bookmaker} />
                                                    </div> : null
                                    ))
                                }
                                <div className='View-more'>
                                            <span style={{cursor: 'pointer'}} onClick={() => handleOddMore(match_odd)}>
                                                View bets & odds detail
                                            </span>
                                </div>
                            </div>
                        </div>
                        {
                            (index !== (odd_list.length - 1) && (index + 1 <= (odd_list.length - 1)) &&
                                odd_list[(index + 1)].league.id === match_odd.league.id) ?
                                <Divider type='horizontal' className='odds-divider-padding' /> : null
                        }
                        {
                            (index === (odd_list.length - 1) && nextLoading) ?
                                <div style={{display: 'flex', justifyContent: 'center', height: '14px'}}>
                                    <img className='circle-progress' src={loadingIcon} width='10px' height='10px' />
                                </div> : null
                        }
                    </div>)
            }
        </div>);
    }

    const handleOddMore = (match_odd) => {
        localStorage.setItem("matchOdd", JSON.stringify(match_odd));
        //navigates to odd detail screen
        window.open(`/odds/detail/${match_odd.fixture.id}`, '_blank');
    }

    useEffect(() => {
            //load match odds
            loadMatchOdds(targetPage, 1);
        }, [targetPage]);

    //gets change i browser height
    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    return (
        <div className='Odds-box'>
            <div className="Odds-loading" hidden={!loading}>
                <img src={loadingIcon} className='circle-progress' height='42px' width='42px'/>
            </div>
            <div className="Odds-error" hidden={!loadError}>
                <div className="alert alert-secondary" role="alert">
                    {noResult}
                </div>
            </div>

            <div className='Odd-container' hidden={loading || loadError}>
                <div>
                    <div ref={odd_container_ref}>{
                        oddCollection.has(current) ? <div>
                            {
                                matchOddPager(oddCollection.get(current))
                            }
                        </div> : null
                    }</div>
                </div>
                <div className='Pagination-box'>
                    <Pagination
                        page={current}
                        count={pageSize}
                        onChange={handlePageChange}
                        shape='rounded'
                        color='standard'
                    />
                </div>
            </div>
        </div>
    );
}

const has1xBet = (bookmakers) => {
    let doesExist = false;
    bookmakers.forEach((maker) => {
        if(maker.name === '1xBet') {
            doesExist = true;
        }
    });

    return doesExist;
}

const hasBoth1xBetAndBet365 = (bookmakers) => {
    let doesExist = false;
    bookmakers.forEach((maker) => {
        if(maker.name === '1xBet' || maker.name === 'Bet365') {
            doesExist = true;
        }
    });

    return doesExist;
}

export  default MatchOddsTab;