import { useParams } from 'react-router-dom';
import '../../styles/NewsDetail.css';
import { useEffect, useState } from 'react';

function NewsDetail() {
    const { newsId } = useParams();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Error fetching News Item!");
    const [loadError, setLoadError] = useState(false);
    const [newsItem, setNewsItem] = useState(false);
    const resetLoads = (loadState, errorState) => {
        setLoading(loadState);
        setLoadError(errorState);
    };

    useEffect(() => {
        let url = `https://vs-sports-server.onrender.com/varitex/api/football/news?id=${newsId}`;
        fetch(url).then(response => response.json())
            .then((result) => {
                if (result.code === 200) {
                    //bind response data to news list
                    setNewsItem(result.data[0]);
                    resetLoads(false, false);

                    console.log(`News Highlights: ${JSON.stringify(result.data)}`);
                }
                else {
                    setMessage(result.message);
                    resetLoads(false, true);
                }
            })
            .catch((error) => {
                setMessage("Network issue. try again!");
                resetLoads(false, true);

                console.log(`+++++++++++++++ News Error: ${error}`);
            });
    }, []);

    return (
        <div className='News-detail'>
            <div hidden={loading || loadError} className="News-detail-container">
                <div className='News-box'>
                    <h3>{newsItem.subTitle}</h3>
                    <p></p>
                    <img src={newsItem.imageUrl} srcSet={newsItem.imageUrls}
                         style={{ width: '100%', objectFit: 'contain', margin: 'auto 0' }}
                    />
                    <p></p>
                    <span>{newsItem.snippets}</span>
                    <span dangerouslySetInnerHTML={{ __html: newsItem.highlights }} />
                    <span dangerouslySetInnerHTML={{ __html: newsItem.highlightsTwo }} />
                </div>
                <div className='Ads-box'>

                </div>
            </div>

            <div hidden={!loading} className='loader'>
                <div className="spinner-border text-danger" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            <div style={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                 hidden={!loadError && !loading}>
                <div className="alert alert-error" role="alert">
                    <span>{message}</span>
                </div>
            </div>
        </div>
    );
}

export default NewsDetail;
