
import Home from './pages/Home';
import NewsDetail from './pages/detail/NewsDetail';
import MatchDetail from './pages/detail/MatchDetail';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import OddsDetail from "./pages/detail/OddsDetail";
import ExamplePage from "./ExamplePage";

function App() {

  return (
      <Router>
        <Routes>
            <Route path='/' element={<Home />}/>
            <Route path='/sports/stories/:id' element={<NewsDetail />}/>
            <Route path='/match/:date/:match' element={<MatchDetail />}/>
            <Route path='/odds/detail/:match' element={<OddsDetail />} />
            <Route path='/other/page' element={<ExamplePage />} />
        </Routes>
      </Router>
  );

}

export default App;
