
import '../../src/styles/MatchTips.css'
import {Tabs} from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import PredictionTab from "../tabs/tips/match_prediction_tab";

function Tips() {

    return (
        <div className='Match-tips-container'>
            <div className='Tips-Ads-box'>
                <div>
                    <Tabs defaultActiveKey='1'>
                        <TabPane tab="Today" key='1'>
                            <PredictionTab date={getTabLabelDate(0, 'value')} />
                        </TabPane>
                        <TabPane tab="Tomorrow" key='2'>
                            <PredictionTab date={getTabLabelDate(1, 'value')} />
                        </TabPane>
                        <TabPane tab={getTabLabelDate(2, 'label')} key='3'>
                            <PredictionTab date={getTabLabelDate(2, 'value')} />
                        </TabPane>
                    </Tabs>
                </div>
                <div className='Ads-box' style={{backgroundColor: 'lightgrey'}}>
                    Ads space
                </div>
            </div>
        </div>
    );
}

const getTabLabelDate = (offset, type) => {
    let today = new Date();
    let required_date = new Date();
    required_date.setDate(today.getDate() + offset);

    if(type === 'label') {
        const options = { weekday: 'short', day: '2-digit', month: 'short' };
        const formattedDate = required_date.toLocaleDateString('en-US', options);

        const parts = formattedDate.split(' ');
        return `${parts[0]} ${parts[1]} ${parts[2]}`;
    }
    else {
        const formattedDate = required_date.toLocaleDateString();
        const dateArray = formattedDate.split('/')

        return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
    }
}

export default Tips;